import React from "react";
import { graphql } from "gatsby";

import PublicSymbolPage from "../../../components/public-symbol-page";

export const query = graphql`
  query HistoricalSymbolsPageQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      descriptionFrench: publicSymbolsHistoricalDescription_french
      description: publicSymbolsHistoricalDescription
    }
    symbols: allSanitySymbol(
      filter: { category: { eq: "historical" }, hidden: { eq: false } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title_french
        title
        category
        slug: slugFr {
          current
        }
        mainImage {
          ...SanityImage
          alt_french
          alt
          caption_french
          caption
        }
        _rawDescriptionFrench(resolveReferences: { maxDepth: 5 })
        _rawDescription(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const HistoricalSymbolsPage = ({ data = {} }) => (
  <PublicSymbolPage
    title="Groupes haineux canadiens historiques"
    data={data}
    isFrench={true}
  />
);

export default HistoricalSymbolsPage;
